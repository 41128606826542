import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { ChangeEvent } from "react";
import { elementsPerPage } from "../../../components/src/MainLayout.web";
import { removeStorageData } from "framework/src/Utilities";

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    apiBody?: object;
    body?: object;
    token?: string;
    type?: string;
}

export interface ValidResponseType {
    message: string;
    data: object;
    scheduled: object;
}

export interface AdminReviewsAndRatingsResponseType {
    reviews: string;
    errors: [{ token: "" }];
    data: ReviewsAndRatingsDataType[];
    meta: {
        page: string;
        total_reviews: number;
    };
}

export interface ReviewsAndRatingsDataType {
    id: number;
    type: string;
    attributes: {
        id: number;
        reviewable_id: number;
        rating: number;
        content: string;
        status: boolean;
        visible_on_landing: boolean;
        user_name: {
            id: number;
            full_phone_number: string;
            email: string;
            full_name: string;
        };
    };
}

export interface InvalidResponseType {
    errors: Array<string>;
}

interface ReviewData {
    data: {
        id: string;
        type: string;
        attributes: {
            id: number;
            reviewable_id: string;
            rating: string;
            content: string;
            status: boolean;
            visible_on_landing: boolean;
            user_name: {
                id: string;
                full_phone_number: string;
                email: string;
                full_name: string;
            };
        };
    };
}

type Item = {
    id: string;
};

const data: Item[] = [
    { id: "1" },
    { id: "2" },
    { id: "3" },
    { id: "4" },
    { id: "5" }
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    adminReviewsAndRatingsData: AdminReviewsAndRatingsResponseType;
    isDrawer: boolean;
    setfullWidth: boolean;
    currentPageCount: number;
    pageCount: number;
    userToken: string;
    loader: boolean;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    perPage: number;
    isHeaderChecked: boolean;
    isChecked: boolean[];
    searchedReviewsAndRatingsText: string;
    openDeleteReviewsAndRatingsModal: boolean;
    deleteReviewsAndRatingsId: string | number;
    openAddReviewModal: boolean;
    isUpdate: boolean;
    courseListData: [];
    studentListData: [];
    courseId: string;
    studentId: string;
    visibility: boolean;
    ratingId: string;
    ratingListData: Item[];
    content: string;
    reviewAndRatingId: string;
    reviewStatus: boolean | null;
    currentSortState: { order: string; field: string };
    columnSort: string;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminReviewsAndRatingsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAdminReviewsAndRatingslistApiCallId: string = "";
    deleteReviewsAndRatingsApiCallId: string = "";
    getCourseListApiCallId: string = "";
    getStudentListApiCallId: string = "";
    postAddReviewApiCallId: string = "";
    showAddReviewApiCallId: string = "";
    editReviewApiCallId: string = "";
    statusUpdateApicallID: string = "";
    visibilityUpdateApiCallID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationIdMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            adminReviewsAndRatingsData: {
                data: [],
                meta: {
                    page: "",
                    total_reviews: 0
                },
                errors: [{ token: "" }],
                reviews: ""
            },
            isDrawer: true,
            setfullWidth: true,
            currentPageCount: 1,
            pageCount: 0,
            userToken: "",
            loader: true,
            isAlert: false,
            alertMsg: "",
            alertType: "success",
            perPage: elementsPerPage,
            isHeaderChecked: false,
            isChecked: [],
            searchedReviewsAndRatingsText: "",
            openDeleteReviewsAndRatingsModal: false,
            deleteReviewsAndRatingsId: "",
            openAddReviewModal: false,
            isUpdate: false,
            courseListData: [],
            studentListData: [],
            ratingListData: data,
            courseId: "",
            studentId: "",
            ratingId: "",
            visibility: false,
            content: "",
            reviewAndRatingId: "",
            reviewStatus: null,
            currentSortState: { order: "desc", field: "id_sort" },
            columnSort: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (!responseJson.errors) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            } else {
                this.parseErrors(responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.getUserToken()
    }

    parseErrors = async (responseJson: ResponseErrors) => {
        if (Object.keys(responseJson.errors[0])[0] === "token") {
            this.navigationLoginScreen();
        } else {
            this.parseApiErrorResponse(responseJson);
        }
    };

    getUserToken = async () => {
        const token = await getStorageData('loginToken');
        this.setState({ userToken: token });
        await this.getReviewsAndRatingsDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedReviewsAndRatingsText, this.state.columnSort);
        await this.handleGetCourselist();
        await this.handleGetStudentlist();
    };

    adminReviewsAndRatingsApiCall = (data: APIPayloadType) => {
        let { endPoint, body, method, contentType, type } = data;
        const header = {
            "Content-Type": contentType,
            "token": this.state.userToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                type === "" ? body : JSON.stringify(body)
            );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage.messageId;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: AdminReviewsAndRatingsResponseType & ReviewData & { data: [] }) => {
        switch (apiRequestCallId) {
            case this.getAdminReviewsAndRatingslistApiCallId:
                this.handleGetReviewsAndRatingsListResponse(responseJson);
                break;
            case this.deleteReviewsAndRatingsApiCallId:
                this.handleDeleteReviewResponse(responseJson);
                break;
            case this.getCourseListApiCallId:
                this.handleCourseListResponse(responseJson);
                break;
            case this.getStudentListApiCallId:
                this.handleStudentListResponse(responseJson);
                break;
            case this.postAddReviewApiCallId:
                this.handleAddReviewResponse(responseJson);
                break;
            case this.showAddReviewApiCallId:
                this.handleShowAdminReviewResponse(responseJson);
                break;
            case this.editReviewApiCallId:
                this.handleEditAdminReviewResponse(responseJson);
                break;
            case this.statusUpdateApicallID:
                this.handleAdminReviewStatusResponse(responseJson);
                break;
            case this.visibilityUpdateApiCallID:
                this.handleAdminReviewVisibilityResponse(responseJson);
                break;
        }
    };

    handleAdminReviewStatusResponse = async (responseJson: object) => {
        await this.getReviewsAndRatingsDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedReviewsAndRatingsText, this.state.columnSort)
        this.setState({ isAlert: true, alertMsg: "Review status is updated successfully." })
    };

    handleAdminReviewVisibilityResponse = async (responseJson: object) => {
        await this.getReviewsAndRatingsDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedReviewsAndRatingsText, this.state.columnSort)
        this.setState({ isAlert: true, alertMsg: "Review visibility is updated successfully." })
    };

    handleEditAdminReviewResponse = (responseJson: object) => {
        let alertMsg, alertType: Color;
        alertMsg = "Review Edited Successfully";
        alertType = "success";
        this.handleFeildReset();
        this.getReviewsAndRatingsDataFunction(1, this.state.perPage, "", this.state.columnSort)
        this.handleCloseAddReviewModal();
        this.setState({ isAlert: true, alertMsg, alertType, loader: false });
    };

    handleShowAdminReviewResponse = (response: ReviewData) => {
        const { attributes } = response.data;
        this.setState({
            isUpdate: true,
            loader: false,
            courseId: attributes.reviewable_id,
            studentId: attributes.user_name.id,
            ratingId: attributes.rating,
            content: attributes.content,
            reviewAndRatingId: response.data.id,
            visibility: attributes.visible_on_landing
        }, () => {
            this.handleOpenAddReviewModal();
        });
    };

    handleAddReviewResponse = (response: object) => {
        let alertMsg, alertType: Color;
        alertMsg = "Review Created Successfully";
        alertType = "success";
        this.handleFeildReset();
        this.getReviewsAndRatingsDataFunction(1, this.state.perPage, "", this.state.columnSort)
        this.handleCloseAddReviewModal();
        this.setState({ isAlert: true, alertMsg, alertType, loader: false });
    };

    handleCourseListResponse = (response: { data: [] }) => {
        this.setState({
            courseListData: response.data,
            loader: false
        });
    };

    handleStudentListResponse = (response: { data: [] }) => {
        this.setState({
            studentListData: response.data,
            loader: false
        });
    };

    handleDeleteReviewResponse = (response: object) => {
        this.setState({
            openDeleteReviewsAndRatingsModal: false,
            deleteReviewsAndRatingsId: "",
            loader: false,
            isAlert: true,
            alertMsg: "Review And Rating Deleted Successfully",
            alertType: "success"
        }, () => this.getReviewsAndRatingsDataFunction(this.state.currentPageCount, this.state.perPage, this.state.searchedReviewsAndRatingsText, this.state.columnSort));
    };

    handleGetReviewsAndRatingsListResponse = (response: AdminReviewsAndRatingsResponseType) => {
        if (response?.reviews === configJSON.recordNotFoundMessage) {
            this.setState({
                isAlert: true,
                alertMsg: "Record not found.",
                alertType: "info",
                loader: false,
                pageCount: 0,
                adminReviewsAndRatingsData: {
                    data: [],
                    meta: {
                        page: "",
                        total_reviews: 0
                    },
                    errors: [{ token: "" }],
                    reviews: ""
                }
            });
        } else if (response && response?.errors) {
            if (response?.errors[0]?.token == configJSON.tokenExpiredMessage) {
                this.navigationLoginScreen()
            }
        } else {
            const pageCount = Math.ceil(response.meta.total_reviews / this.state.perPage);
            this.setState({
                adminReviewsAndRatingsData: response,
                loader: false,
                pageCount: pageCount
            });
        }
    };

    handleCloseAddReviewModal = () => {
        this.setState({
            openAddReviewModal: false,
        }, () => this.handleFeildReset());
    };

    handleOpenAddReviewModal = () => {
        this.setState({
            openAddReviewModal: true,
        });
    };

    handleCourseSelectChange = (reviewId: unknown) => {
        this.setState({
            courseId: reviewId as string
        });
    };

    handleStudentSelectChange = (reviewId: unknown) => {
        this.setState({
            studentId: reviewId as string
        });
    };

    handleRatingSelectChange = (reviewId: unknown) => {
        this.setState({
            ratingId: reviewId as string
        });
    };

    handleContent = (event: { target: { value: string } }) => {
        this.setState({
            content: event.target.value
        });
    };

    handleFeildReset = () => {
        this.setState({
            isUpdate: false,
            studentId: "",
            courseId: "",
            ratingId: "",
            content: ""
        });
    };

    handleReviewHeaderCheckboxChange = () => {
        this.setState(prevState => {
            const isHeaderChecked = !prevState.isHeaderChecked;
            const isChecked = Array(prevState.adminReviewsAndRatingsData.data.length).fill(isHeaderChecked);

            return { isHeaderChecked, isChecked };
        });
    };

    handleReviewRowCheckboxChange = (index: number) => {
        this.setState(prevState => {
            const isChecked = [...prevState.isChecked];
            isChecked[index] = !isChecked[index];
            const isHeaderChecked = isChecked.every(value => value);

            return { isChecked, isHeaderChecked };
        });
    };

    onCloseAlert = () => {
        this.setState({ isAlert: false });
    };

    toggleDrawer = () => {
        this.setState(prevState => ({
            isDrawer: !prevState.isDrawer,
            setfullWidth: !prevState.setfullWidth,
        }));
    };

    onReviewsAndRatingsPageChange = (event: ChangeEvent<unknown>, value: number) => {
        const newPageCount = Number(value);
        this.setState({ currentPageCount: newPageCount }, () => {
            this.getReviewsAndRatingsDataFunction(newPageCount, this.state.perPage, this.state.searchedReviewsAndRatingsText, this.state.columnSort);
        });
    };

    handleCloseDeleteReviewsAndRatingsModal = () => {
        this.setState({
            openDeleteReviewsAndRatingsModal: false,
            deleteReviewsAndRatingsId: ""
        });
    };

    handleOpenDeleteReviewsAndRatingsModal = (reviewId: number) => {
        this.setState({
            openDeleteReviewsAndRatingsModal: true,
            deleteReviewsAndRatingsId: reviewId
        });
    };

    handleVisibilityCheck = (event: { target: { checked: boolean } }) => {
        this.setState({
            visibility: event.target.checked
        });
    };

    handleShowReview = async (reviewId: string | number) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.showReviewsAndRatingsAPIEndPoint}/${reviewId}`;
        this.showAddReviewApiCallId = this.adminReviewsAndRatingsApiCall({
            contentType: configJSON.ReviewsAndRatingsApiContentType,
            method: configJSON.httpGetType,
            endPoint
        });
    };

    getBlankField = (courseId: string, studentId: string, ratingId: string, content: string) => {
        if (!studentId) return "Student Email";
        if (!courseId) return "Course Name";
        if (!content) return "Content";
        if (!ratingId) return "Rating";
        return null;
    }

    handlePostReview = async () => {
        this.setState({ loader: true })
        const { courseId, studentId, ratingId, content, visibility } = this.state;
        const blankField = this.getBlankField(courseId, studentId, ratingId, content);
        if (blankField) {
            this.setState({
                isAlert: true,
                alertMsg: `${blankField} cannot be blank`,
                alertType: "error",
                loader: false
            });
        } else {
            let body = {
                data: {
                    account_id: studentId,
                    reviewable_id: courseId,
                    rating: ratingId,
                    content: content,
                    visible_on_landing: visibility
                }
            }
            this.postAddReviewApiCallId = await this.adminReviewsAndRatingsApiCall({
                contentType: configJSON.ReviewsAndRatingsApiContentType,
                method: configJSON.httpPostType,
                endPoint: configJSON.postReviewsAndRatingsAPIEndPoint,
                body: body
            })
        }
    };

    handleEditReview = async () => {
        this.setState({ loader: true });
        const { courseId, studentId, ratingId, content, reviewAndRatingId, visibility } = this.state;
        if (!content.trim()) {
            this.setState({ isAlert: true, alertMsg: "Content cannot be blank", alertType: "info", loader: false });
            return;
        }
        let body = {
            data: {
                account_id: studentId,
                reviewable_id: courseId,
                rating: ratingId,
                content: content,
                visible_on_landing: visibility
            }
        }
        this.editReviewApiCallId = await this.adminReviewsAndRatingsApiCall({
            contentType: configJSON.ReviewsAndRatingsApiContentType,
            method: configJSON.httpPutType,
            endPoint: `${configJSON.updateReviewsAndRatingsAPIEndPoint}/${reviewAndRatingId}`,
            body: body
        })
    };

    handleReviewVisibility = async (reviewId: number, accountId: number) => {
        const updatedVisibilities = this.state.adminReviewsAndRatingsData.data.map(review =>
            review.id === reviewId ? {
                ...review,
                attributes: {
                    ...review.attributes,
                    visible_on_landing: !review.attributes.visible_on_landing
                }
            } : review
        );
        this.setState({
            adminReviewsAndRatingsData: {
                data: updatedVisibilities,
                reviews: "",
                errors: [{ token: "" }],
                meta: {
                    page: "",
                    total_reviews: 0
                }
            }
        })
        const clickedData: ReviewsAndRatingsDataType | undefined = updatedVisibilities.find(review => review.id === reviewId);
        const Value = clickedData?.attributes.visible_on_landing;
        const body = {
            data: {
                visible_on_landing: Value,
                account_id: accountId,
            }
        }
        this.visibilityUpdateApiCallID = this.adminReviewsAndRatingsApiCall({
            contentType: configJSON.ReviewsAndRatingsApiContentType,
            method: configJSON.httpPutType,
            endPoint: `${configJSON.updateReviewsAndRatingsAPIEndPoint}/${reviewId}`,
            body: body
        })
    };

    handleReviewStatus = async (reviewId: number, accountId: number) => {
        const updatedNotifications = this.state.adminReviewsAndRatingsData.data.map(notification =>
            notification.id === reviewId ? {
                ...notification,
                attributes: {
                    ...notification.attributes,
                    status: !notification.attributes.status
                }
            } : notification
        );
        this.setState({
            adminReviewsAndRatingsData: {
                data: updatedNotifications,
                reviews: "",
                errors: [{ token: "" }],
                meta: {
                    page: "",
                    total_reviews: 0
                }
            }
        })
        const clickedData: ReviewsAndRatingsDataType | undefined = updatedNotifications.find(notification => notification.id === reviewId);
        const Value = clickedData?.attributes.status;
        let body = {
            data: {
                status: Value,
                account_id: accountId,
            }
        }
        this.statusUpdateApicallID = await this.adminReviewsAndRatingsApiCall({
            contentType: configJSON.ReviewsAndRatingsApiContentType,
            method: configJSON.httpPutType,
            endPoint: `${configJSON.updateReviewsAndRatingsAPIEndPoint}/${reviewId}`,
            body: body
        })
    };

    handleReviewsAndRatingsSearch = (event: { target: { value: string } }) => {
        const searchedReviewsAndRatingsText = event.target.value;
        this.setState({ searchedReviewsAndRatingsText, currentPageCount: 1 }, () => {
            this.getReviewsAndRatingsDataFunction(this.state.currentPageCount, this.state.perPage, searchedReviewsAndRatingsText, this.state.columnSort);
        });
    };

    getReviewsAndRatingsDataFunction = async (pageNo: number, per_page: number, searchedReviewsAndRatingsString: string, sorting: string) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.getAllReviewsAndRatingsAPIEndPoint}?page=${pageNo}&per_page=${per_page}&search_params=${searchedReviewsAndRatingsString}${sorting || ""}`;
        this.getAdminReviewsAndRatingslistApiCallId = this.adminReviewsAndRatingsApiCall({
            contentType: configJSON.ReviewsAndRatingsApiContentType,
            method: configJSON.httpGetType,
            endPoint
        });
    };

    handleSort(field: string) {
        const sortParams: Record<string, string> = {
            ["S.No"]: "id_sort",
            Name: "name_sort",
            Status: "status_sort",
        };
        let fieldSort = "";
        const sortOrder =
        this.state.currentSortState.order === "asc" ? "desc" : "asc";

        if (sortParams.hasOwnProperty(field)) {
            fieldSort = `&${sortParams[field]}=${sortOrder}`;
        }
        this.toggleSortState(field, fieldSort);
        this.getReviewsAndRatingsDataFunction(
            this.state.currentPageCount,
            this.state.perPage,
            this.state.searchedReviewsAndRatingsText,
            fieldSort
        );
    }

    toggleSortState = (field: string, columnSort: string) => {
        this.setState({
            columnSort,
            currentSortState: {
                field,
                order: this.state.currentSortState.order === "asc" ? "desc" : "asc",
            },
        });
    };

    handleGetCourselist = async () => {
        this.setState({ loader: true });
        const endPoint = configJSON.getCourseListAPIEndPoint;
        this.getCourseListApiCallId = this.adminReviewsAndRatingsApiCall({
            contentType: configJSON.ReviewsAndRatingsApiContentType,
            method: configJSON.httpGetType,
            endPoint
        });
    };

    handleGetStudentlist = async () => {
        this.setState({ loader: true });
        const endPoint = configJSON.getAllStudentListAPIEndPoint;
        this.getStudentListApiCallId = this.adminReviewsAndRatingsApiCall({
            contentType: configJSON.ReviewsAndRatingsApiContentType,
            method: configJSON.httpGetType,
            endPoint
        });
    };

    handleDeleteReviewsAndRatings = async (reviewsId: string | number) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.deleteReviewsAndRatingsAPIEndPoint}/${reviewsId}`;
        this.deleteReviewsAndRatingsApiCallId = this.adminReviewsAndRatingsApiCall({
            contentType: configJSON.ReviewsAndRatingsApiContentType,
            method: configJSON.httpDeleteType,
            endPoint
        });
    };

    async handleLogoutUser() {
        await removeStorageData("emailOTPToken");
        await removeStorageData("loginEmail");
        await removeStorageData("loginToken");
        await removeStorageData("profilePic");
        await removeStorageData("profileUsername");
        await removeStorageData("userRole");
        await removeStorageData('wishlistCount');
    }

    navigationLoginScreen = () => {
        const messageLogin: Message = new Message(
            getName(MessageEnum.NavigationEmailLogInMessage)
        );
        messageLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.handleLogoutUser();
        this.send(messageLogin);
    };
    // Customizable Area End
}