Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";

exports.chseExpressText = "CHSE Express";
exports.addressText = "Address";
exports.completeAddress = "Vivekanandamarg, Post- Srikanthapur Tanishq Jewellery Building, Chidiapool Balasore, Pin Code: 756001";
exports.mobileText = "Mobile";
exports.fullMobileText = "9653872457";
exports.emailText = "Email";
exports.fullmailText = "info@chseexpress.com";
exports.customerService = "Customer Service";
exports.whatsapp = "Whatsapp";
exports.facebook = "Facebook";
exports.twitter = "Twitter";
exports.instagram = "Instagram";
exports.youtube = "Youtube";
exports.telegram = "Telegram";
exports.headingTitle = "Contact Us";
exports.headingSubTitle = "Save and curate your desired courses, and easily plan your learning journey and access to your favorite educational resources with just a click."
exports.contactUsWebApiEndPoint = "/bx_block_profile/footers/show_footer"

// Customizable Area End