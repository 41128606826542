import React from "react";

// Customizable Area Start
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("../../blocks/landingpage/src/config");
const defaultProfilePic =
  'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg';
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  cartCounts?:number;
  wishlistCounts?:number;
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface FooterResponse {
  data: FooterData;
  errors: any[];
  message: string;
}

export interface FooterData {
  address: string;
  full_phone_number: string;
  email: string;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  userToken: string;
  username: string;
  profilePic: string;
  cartCount: number;
  wishlistCount: number;
  search: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HeaderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFooterListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      isLoading: false,
      userToken: "",
      username: 'USERNAME_TESTING_PURPOSE_ONLY',
      profilePic: defaultProfilePic,
      cartCount: 0,
      wishlistCount: 0,
      search: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.addEventListener('storage', this.handlerFetch);
    window.addEventListener('cartCountChange', this.handleCartCountChange as EventListener);
    window.addEventListener('wishlistCountChange', this.handleWishlistCountChange as EventListener);
    await this.fetchProfilePic();
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    const cartCount = await getStorageData('cartCount');
    const wishlistCount = await getStorageData('wishlistCount');
    if(cartCount) {
      this.setState({ cartCount })
    }
    if(this.props.cartCounts) {
      this.setState({ cartCount : this.props.cartCounts })
    }
    if (wishlistCount) {
      this.setState({ wishlistCount: wishlistCount});
    }
    if (this.props.wishlistCounts) {
      this.setState({ wishlistCount: this.props.wishlistCounts});
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleCartCountChange = (event: CustomEvent) => {
    this.setState({ cartCount: event.detail.count });
  }
  handleWishlistCountChange = (event: CustomEvent) => {
    this.setState({ wishlistCount: event.detail.count });
  }
  apiCall = (data: APIPayload) => {
    let { contentType, method, endPoint, body, type, token } = data;
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      ...(contentType ? { "Content-Type": contentType } : {}),
      token
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        !type ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async componentDidUpdate() {
    await this.fetchProfilePic();
    const token = await getStorageData('loginToken');
  }

  async handlerFetch() {
    await this.fetchProfilePic();
  }

  async componentWillUnmount() {
    window.removeEventListener('storage', this.handlerFetch);
    window.removeEventListener('cartCountChange', this.handleCartCountChange as EventListener);
    window.removeEventListener('wishlistCountChange', this.handleWishlistCountChange as EventListener);
  }

  fetchProfilePic: () => Promise<void> = async () => {
    try {
      const username: string =
          (await getStorageData('profileUsername')) ||
          'USERNAME_TESTING_PURPOSE_ONLY',
        profilePic: string =
          (await getStorageData('profilePic')) || defaultProfilePic;
      if (username && username.split(' ')[0] !== this.state.username) {
        this.setState({
          profilePic: profilePic,
          username: username.split(' ')[0],
        });
      }
      if (profilePic !== this.state.profilePic) {
        this.setState({
          profilePic: profilePic,
        });
      }
    } catch (error) {
      if (this.state.username !== 'USERNAME_TESTING_PURPOSE_ONLY') {
        this.setState({
          username: 'USERNAME_TESTING_PURPOSE_ONLY'
        });
      }
      if (this.state.profilePic !== defaultProfilePic) {
        this.setState({ profilePic: defaultProfilePic });
      }
    }
  };

  navigationToScreen = (route: string) => {
    if(this.props.navigation) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), route);
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            const raiseMessage: Message = new Message(
              getName(MessageEnum.NavigationPayLoadMessage)
            );
            raiseMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), { screenName : "All Courses" });
            message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
            this.send(message)
    } else {
        const a = document.createElement('a');
        a.href = `/${route}`;
        a.click();
    }

  };

  handleSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.target.value });
  }
  navigateToSearchResultScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SearchCourses");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationInfoPageMessage), { searchParams: this.state.search });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
