import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Chip,
  withStyles,
  Theme,
  createStyles,
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  styled
} from "@material-ui/core";
import { Close as CloseIcon, Check as CheckIcon } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import { MdCurrencyRupee } from "react-icons/md";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  PiClipboardTextLight,
  PiGraduationCap,
  PiBookOpenText,
  PiUser,
  PiLockKeyFill
} from "react-icons/pi";
import { BsDot } from "react-icons/bs";
import { FaCirclePlay, FaCircleCheck } from "react-icons/fa6";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import CourseDetailController, {
  CourseDetailsAttributes,
  Props,
  configJSON
} from "./CourseDetailController.web";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { BiChalkboard } from "react-icons/bi";
import { IoLanguageSharp } from "react-icons/io5";
import { FiShare2 } from "react-icons/fi";
import { FaRegClock, FaStar } from "react-icons/fa";
import IconButton from "@material-ui/core/IconButton";
import Footer from "../../../components/src/Footer.web";
import {
  BundleCoursesAttributes,
  CustomDialog,
  LaunchedCourses,
  LaunchedCoursesAttributes,
  getNewLaunchCoursesWeb,
  CardType,
  webStyle
} from "../../landingpage/src/LandingPage.web";
import {
  courseDetailBg,
  facebookIcon,
  telegramICon,
  whatsappIcon,
  instagramIcon,
  clockIcon,
  backArrowPage,
  forwardArrowPage
} from "./assets";
import HeaderWeb from "../../../components/src/Header.web";
import { LiaRupeeSignSolid } from "react-icons/lia";

let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);

const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'Inter',
  width: "50%" ,
  textWrap: 'nowrap', 
  textOverflow: 'ellipsis', 
  overflow: 'hidden'
}))

const CustomBox = styled(Box)({
   height: 1120, 
   overflowY: "auto",
   padding: '0px 8px',
    '&::-webkit-scrollbar': {
        width: 0,
        background: 'transparent'
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#578D92',
        borderRadius: '4px',
      },
})

const parserOptions: HTMLReactParserOptions = {
  replace: domNode => {
    if (domNode.type.toString() === "script") {
      return <></>;
    }
  }
};

const obj = [
  {
    label: "Instructor",
    icon: <PiUser fontSize={"inherit"} />,
    valueName: "instructor_name"
  },
  {
    label: "Chapters",
    icon: <PiBookOpenText fontSize={"inherit"} />,
    valueName: "total_content"
  },
  {
    label: "Lecture",
    icon: <BiChalkboard fontSize={"inherit"} />,
    valueName: "course_class_count"
  },
  {
    label: "Duration",
    icon: <FaRegClock fontSize={"inherit"} />,
    valueName: "all_course_class_duration"
  },
  {
    label: "Enrolled",
    icon: <PiGraduationCap fontSize={"inherit"} />,
    valueName: "student_count"
  },
  {
    label: "Languages",
    icon: <IoLanguageSharp fontSize={"inherit"} />,
    valueName: "language_name"
  }
];

// Customizable Area End

export default class CourseDetail extends CourseDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    this.handleWishlistStatus = this.handleWishlistStatus.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  showSkeleton(amount = 4) {
    return (
      <>
        {Array(amount)
          .fill(null)
          .map(() => (
            <Skeleton variant="rect" className="card" height={378} />
          ))}
      </>
    );
  }

  stringToHTML(htmlString: string) {
    return (
      <Typography
        variant="h6"
        className="description-wrapper"
        style={{
          margin: "16px 0",
          marginTop: 0,
          padding: 0,
          WebkitLineClamp: 3,
          height: 100,
          display: "-webkit-box",
          textOverflow: "ellipsis",
          overflow: "hidden",
          WebkitBoxOrient: "vertical"
        }}
      >
        {this.truncateText(htmlString, 230)}
      </Typography>
    );
  }

  stringToBoolean(value: string): boolean {
    return value.toLowerCase() === "true";
  }

  handleCoursesPagination = <T, _undefined>(
    array: T[],
    pageNumber: number,
    itemsPerPage: number
  ) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return array.slice(startIndex, endIndex);
  };

  showCoursePrice(value: LaunchedCoursesAttributes | BundleCoursesAttributes) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          columnGap: "10px"
        }}
      >
        <Typography
          style={{
            display: "inline-flex",
            columnGap: "10px",
            alignItems: "center",
            fontWeight: "bold"
          }}
          variant="h5"
          component="h3"
          className="course-price"
        >
          <MdCurrencyRupee color="inherit" fontSize="inherit" />
          {Math.round(value?.discounted_price || 0)}/-
        </Typography>
        {value?.first_year_price ? (
          <Typography
            style={{
              display: "inline-flex",
              columnGap: "10px",
              alignItems: "center"
            }}
            className="course-price-discount"
            variant="body1"
          >
            <MdCurrencyRupee color="inherit" fontSize="inherit" />
            {Math.round(value.first_year_price)}/-
          </Typography>
        ) : null}
      </div>
    );
  }

  renderScreen = () => {
    const value = this.state.courseDetail as CourseDetailsAttributes,
      isPurchasedOrCart = value.is_purchased || value.is_cart, isPurchased = value.is_purchased;
    return (
      <>
        <Grid
          container
          style={{
            position: "relative",
            background: "rgba(60,116,129,1)",
            paddingTop: 32,
            height: 346
          }}
          spacing={3}
        >
          <Grid
            item
            xs={12}
            style={{
              maxWidth: 1440,
              width: "100%",
              margin: "0 auto",
              padding: "0 55px"
            }}
          >
            <Grid container style={{ position: "relative" , height: '100%'}}>
              <Grid item xs={8} style={{ ...coursesStyles.column }}>
                <div style={coursesStyles.header}>
                  <Typography
                    variant="h3"
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      wordBreak: 'break-word' as const,
                      WebkitLineClamp: 2,
                      display: "-webkit-inline-box",
                      WebkitBoxOrient: "vertical" as const,
                      textTransform: "capitalize" as const,
                      hyphens: 'auto' as const
                    }}
                  >
                    {this.truncateText(value.course_name, 60)}
                  </Typography>
                  {this.stringToHTML(value.short_description)}
                  <Typography
                    variant="h6"
                    style={coursesStyles.smallDescription}
                  >
                    <Chip
                      label={
                        value.course_child_category &&
                        value.course_child_category.name
                      }
                      style={{ background: "#ff5c01", color: "white" }}
                    />
                    <div>
                      Created by{" "}
                      <strong style={{ color: "#FCB910" }}>CHSE Express</strong>
                    </div>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px'
                    }}>
                      <FaStar color="#FFD735" />{" "}
                      <span>{value.average_rating.Average_rating}</span>
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        gap: 5,
                        alignItems: "center"
                      }}
                    >
                      <img src={clockIcon} height={18} width={18} />{" "}
                      <span data-test-id="hoursToMinutes">
                        {this.convertToHoursAndMinutes(
                          value.all_course_class_duration
                        )}
                      </span>
                    </div>
                  </Typography>
                </div>
              </Grid>
             <Grid item xs={4} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            position: 'relative',
            paddingTop: 0,
            maxWidth: 1440,
            width: "100%",
            margin: "0 auto",
            padding: "0 38px"
          }}
          spacing={3}
        >
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            background: `url(${courseDetailBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '200%',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
            zIndex: -1,
          }} />
          <Grid item xs={8} style={{ ...coursesStyles.column , 
            height: 1190,
            padding: '12px 20px' }}>
            <StyledTabs
              value={this.state.currentTab}
              onChange={(_e, value) =>
                this.setState({
                  currentTab: value
                })
              }
              data-test-id="tabsTestId"
              aria-label="styled tabs example"
            >
              <StyledTab label="OVERVIEW" />
              <StyledTab label="COURSE" />
              <StyledTab label="INSTRUCTOR" />
              <StyledTab label="REVIEW" />
            </StyledTabs>
            <CustomBox className="CustomBoxContainer">
            {this.renderTab(value)}
            </CustomBox>
          </Grid>
          <Grid
                item
                xs={4}
                ref={this.stepInput}
                style={{
                  ...coursesStyles.column,
                  marginTop: -240,
                  gap: "1rem",
                  zIndex: 6
                }}
              >
                <div style={{...coursesStyles.rightColumnCard }}>
                  <div style={{ position: "relative" }}>
                    <img
                      data-test-id="course_thumbnail"
                      src={value.course_thumbnail}
                      style={{
                        borderRadius: "10.61px",
                        height: "auto",
                        width: "100%",
                        aspectRatio: "16/9"
                      }}
                      alt="course thumbnail"
                      height={"auto"}
                      width={"100%"}
                      onClick={this.handleCourseVideoPlay}
                    />
                    <FaCirclePlay
                      style={{
                        position: "absolute",
                        transform: "translate(-50%, -50%)",
                        top: "50%",
                        left: "50%",
                        fontSize: 32,
                        color: "white"
                      }}
                      width={39}
                      height={39}
                      onClick={this.handleCourseVideoPlay}
                    />
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      gap: 10,
                      alignItems: "center"
                    }}
                  >
                    <Typography variant="h4" style={{ width: "50%" , display: 'flex', alignItems: 'center'}}>
                      <MdCurrencyRupee fontSize={"inherit"} />{" "}
                      <strong style={{ color: "#00be10" }}>
                        {Math.round(value?.discounted_price || 0)}/-
                      </strong>
                    </Typography>
                    {value.first_year_price ? (
                      <Typography
                        variant="h6"
                        style={{
                          width: "50%",
                          color: "#BDBCBC"
                        }}
                      >
                        <div
                          style={{
                            textDecorationLine: "line-through",
                            textDecorationColor: "grey"
                          }}
                        >
                          <MdCurrencyRupee fontSize={"inherit"} />{" "}
                          {Math.round(value.first_year_price)}/-
                        </div>
                      </Typography>
                    ): null}
                  </div>
                  <div style={{ ...coursesStyles.column, gap: "10px" }}>
                    <Button
                      variant="contained"
                      style={coursesStyles.containedButton}
                      fullWidth
                      data-test-id="buyNowTestId"
                      onClick={() =>
                        !isPurchased && this.handleOpenCourseValidityPopup(
                          isPurchasedOrCart,
                          {
                            attributes: this.state.courseDetail,
                            type: "course",
                            id: +this.state.courseId,
                            redirect: true
                          },
                          "trendingCourses",
                          +this.state.courseId
                        )
                      }
                    >
                      <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        {isPurchased ? <FaCircleCheck color="inherit" fontSize="inherit" /> : null }
                        {isPurchased ? "PURCHASED" : "BUY NOW"}
                      </Typography>
                    </Button>
                    {!isPurchased &&
                    <Button
                      variant="outlined"
                      data-test-id="addToCartTestID"
                      style={{...coursesStyles.outlinedButton}}
                      fullWidth
                      onClick={() =>
                        !isPurchasedOrCart && this.handleOpenCourseValidityPopup(
                          isPurchasedOrCart,
                          {
                            attributes: this.state.courseDetail,
                            type: "course",
                            id: +this.state.courseId
                          },
                          "trendingCourses",
                          +this.state.courseId
                        )
                      }
                    >
                      <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' , gap: 8 }}>
                        {isPurchasedOrCart ? <FaCircleCheck color="inherit" fontSize="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
                        {isPurchasedOrCart
                          ? "ADDED"
                          : "ADD TO CART"}
                      </Typography>
                    </Button>}
                  </div>
                  {this.courseDetailList(value)}
                </div>
                {this.state.relatedCourses &&
                this.state.relatedCourses.length ? (
                  <div style={coursesStyles.rightColumnCard}>
                    <Typography variant="h5" style={{ margin: 0 }}>
                      Related Courses
                    </Typography>
                    {this.state.relatedCourses.slice(0, 2).map(item => (
                      <Link
                        to={`/course/${item.id}`}
                        style={{
                          textDecoration: "none",
                          color: "black",
                          boxShadow: "0px 2px 6px 0px #00000046",
                          display: "flex",
                          flexDirection: "column",
                          gap: 10,
                          borderRadius: 10.61,
                          padding: 12,
                          boxSizing: "border-box"
                        }}
                      >
                        <img
                          src={item.attributes.course_thumbnail}
                          style={{
                            borderRadius: "10.61px",
                            height: "142px",
                            width: "100%"
                          }}
                          alt="course thumbnail"
                          height={"142px"}
                          width={"auto"}
                        />
                        <Typography
                          variant="h6"
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          {item.attributes.course_name}
                        </Typography>
                        <Typography variant="subtitle1">
                          {item.attributes.course_class_count} chapters{" "}
                          <BsDot /> {item.attributes.total_content} lectures
                        </Typography>
                      </Link>
                    ))}
                  </div>
                ) : null}
              </Grid>
        </Grid>
      </>
    );
  };

  renderScreenLoading = () => {
    return (
      <>
        <div style={{ ...coursesStyles.column, ...coursesStyles.leftColumn, margin: '0px auto' }}>
          <div style={coursesStyles.header}>
            <h1
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              <Skeleton variant="text" />
            </h1>
            <Skeleton variant="rect" width={"100%"} height={100} />
            <Skeleton variant="text" />
          </div>
          <Skeleton variant="rect" width={"100%"} height={350} />
        </div>
        <div
          style={{
            ...coursesStyles.column,
            ...coursesStyles.rightColumn
          }}
        >
          <div style={coursesStyles.rightColumnCard}>
            <Skeleton variant="rect" width={"100%"} height={400} />
          </div>
        </div>
      </>
    );
  };

  renderTab(value: CourseDetailsAttributes) {
    switch (this.state.currentTab) {
      case 0: // Overview
        return this.renderOverview(value);
      case 1: // Course
        return this.renderCourse(value);
      case 2: // Instructor
        return  <div>
        <Typography
          variant="h6"
          style={{
            color: "#858585",
            fontWeight: "bold",
            margin: "15px 0"
          }}
        >
          Instructors
        </Typography>
        <div
        style={{
          display: "flex",
          gap: 10,
          width: "100%",
          boxSizing: "border-box",
          padding: 10,
          borderRadius: 12
        }}
        >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            width: "96%",
            gap: 16
          }}
        >
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              minHeight: 100,
              gap: 20,
              boxShadow: 'rgba(0, 0, 0, 0.145) 0px 1px 8px 0px',
              background: '#fff',
              alignItems: 'center',
              padding: "8px 12px"
            }}
          >
            <div>
              <img
                src={value.instructor_details.profile_icon}
                style={{ width: 120, height: 80 }}
              />
            </div>
            <div>
              <Typography variant="h6" style={{ margin: 0, fontFamily: 'Inter', fontSize: 28 }}>
                {value.instructor_name}
              </Typography>
              <Typography
                variant="body1"
                style={{ margin: 0, fontWeight: "normal", fontFamily: 'Inter', fontSize: 20 }}
              >
                {value.instructor_details.leacture_in}
              </Typography>
            </div>
          </div>
        </div>
        </div>
      </div>
        break;
      case 3: // Review
        break;
    }
  }

  renderCourse(value: CourseDetailsAttributes) {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "99%" }}>
        <Typography
          variant="h4"
          style={{ ...coursesStyles.titleText, marginBottom: 0 }}
        >
          Course Contents
        </Typography>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 12
          }}
        >
          <Typography variant="h6">
            {value.total_content} Chapters <BsDot /> {value.course_class_count}{" "}
            Lectures
            <BsDot />{" "}
            {this.convertToHoursAndMinutes(
              value.all_course_class_duration
            )}{" "}
            Total Long
          </Typography>
          <Button
            endIcon={<ArrowForwardIcon htmlColor="#1721CD" />}
            variant="outlined"
            data-test-id="expandAllAccordions"
            style={coursesStyles.courseViewAll}
            onClick={() =>
              this.handleExpandAllAccordions(
                value.specfifc_content_duration.map(e => e.id)
              )
            }
          >
            <Typography variant="h6" style={coursesStyles.viewAllText}>
              {this.state.openedAccordions.length === value.specfifc_content_duration.map(e => e.id).length ? "COLLAPSE ALL" : "EXPAND ALL"}
            </Typography>
          </Button>
        </div>
        <div style={{ marginTop: 10 }}>
          {value.specfifc_content_duration.map(v => {
            const isExpanded = !!this.state.openedAccordions.find(
              x => x === v.id
            );
            return (
              <Accordion
                expanded={isExpanded}
                data-test-id="accordionTestId"
                onChange={() => this.handleAccordionChange(v.id)}
              >
                <AccordionSummary
                  expandIcon={
                    isExpanded ? (
                      <RemoveIcon htmlColor="white" />
                    ) : (
                      <AddIcon htmlColor="white" />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  key={v.id}
                >
                  <Typography
                    component="h6"
                    style={{
                      fontWeight: "bold"
                    }}
                  >
                    {this.truncateText(`${v.name} ${v.name} ${v.name} ${v.name}`, 40)}
                  </Typography>
                  <Typography
                    component="span"
                    style={{ marginLeft: "auto", marginRight: 30 }}
                  >
                    {v.class_count} Classes
                  </Typography>
                  <Typography component="span">
                    {this.convertToTimeFormat(v.duration_count)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ display: "flex", flexDirection: "column", gap: 12 , paddingTop: 24 }}
                >
                  <div
                    style={{ width: "100%", display: "inline-flex", gap: 16 }}
                  >
                    <img
                      src={v.course_chapter_thumbnail}
                      style={{
                        borderRadius: 10,
                        maxHeight: "136px",
                        width: "255px",
                        height: "auto",
                        aspectRatio: "16/9"
                      }}
                    />
                    <Typography style={{ margin: 0 }}>{v.details}</Typography>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 5 }}
                  >
                    {v.classes_data.map((val, i) => (
                      <span
                        style={{
                          padding: "10px 5px",
                          borderBottom:
                            v.classes_data.length === i
                              ? ""
                              : "1px solid black",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <Typography
                          component="h6"
                          style={{
                            display: "inline-flex",
                            gap: 10,
                            alignItems: "center"
                          }}
                        >
                          <PiLockKeyFill fontSize={"32"} color="inherit" />
                          <span style={{ fontSize: 25, fontFamily: 'Inter'}}>{val.title}</span>
                        </Typography>
                        <Typography component="h6" style={{ fontSize: 21, fontFamily: 'Inter' }}>
                          {this.convertToTimeFormat(parseFloat(val.duration))}{" "}
                        </Typography>
                      </span>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    );
  }

  renderOverview(value: CourseDetailsAttributes) {
    return (
      <>
        <Typography variant="h4" style={coursesStyles.titleText}>
          Course Overview
        </Typography>
        <Box>
        <Typography
              className="AboutUsDescription"
              dangerouslySetInnerHTML={{
                __html: `${value.full_description}`
              }}
            />
        </Box>
        <div
          style={{
            ...coursesStyles.column,
            ...coursesStyles.rightColumnCard,
            padding: 5
          }}
        >
          <div>
            <div
              style={{
                display: "inline-flex",
                gap: 10,
                width: "100%",
                boxSizing: "border-box",
                padding: 10,
                borderRadius: 12,
                background: "white",
              }}
            >
              <div style={{ position:"relative" }}>
              <img
                src={value.course_thumbnail}
                style={{
                  borderRadius: "10.61px",
                  height: "166px",
                  width: "266px"
                }}
                alt="course thumbnail"
                height={"142px"}
                width={"auto"}
              />
                <FaCirclePlay
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    top: "50%",
                    left: "50%",
                    fontSize: 32,
                    color: "white"
                  }}
                  width={39}
                  height={39}
                />
              </div>
              
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "75%"
                }}
              >
                <div style={coursesStyles.aboutCourse}>
                  <div
                    className="course-cat-name"
                    style={{
                      borderColor: value.course_child_category.color,
                      paddingBottom: 0,
                      maxWidth: "200px",
                      width: 'unset'
                    }}
                  >
                    <CircleIcon
                      fontSize="inherit"
                      htmlColor={value.course_child_category.color}
                    />
                    <Typography
                      variant="body2"
                      className="course-cat-name-text"
                    >
                      <strong style={{ fontSize: 18, fontFamily: 'Inter' }}>
                        {value.course_child_category &&
                          value.course_child_category.name}
                      </strong>
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      gap: "8",
                      alignItems: "center"
                    }}
                  >
                    <FaStar fontSize={"inherit"} color="#FFD735" />
                    <Typography
                      variant="body2"
                      className="student-count-rating"
                      style={{
                        display: "inline-flex",
                        gap: 5,
                        alignItems: "center",
                        marginLeft: 12,
                        fontWeight: 600,
                        fontSize: 18,
                        fontFamily: 'Inter' 
                      }}
                    >
                      <strong style={{ color: "black" }}>
                        {value.average_rating.Average_rating}
                      </strong>{" "}
                      | {value.student_count} Students
                    </Typography>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                <div>
                  <Typography variant="h6" style={{ marginTop: 10, fontFamily: 'Inter', fontSize: 24, fontWeight: 600 }}>
                    {value.course_name}
                  </Typography>
                </div>
                <div>
                <Typography
                  variant="h4"
                  component="h3"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    // marginTop: 30
                  }}
                >
                  <MdCurrencyRupee fontSize={"inherit"} />{" "}
                  <strong style={{ color: "#00be10" }}>
                    {Math.round(value?.discounted_price || 0)}/-
                  </strong>
                </Typography>
              </div>
            </div>
              </div>
              
            </div>
          </div>
        </div>
      </>
    );
  }

  renderView = () => {
    const trendingCoursesSort = this.handleCoursesPagination<
      LaunchedCourses,
      undefined
    >(this.state.trendingCourses, this.state.pageNo, 4);
    return (
      <div style={coursesStyles.mainLayout}>
        <Box sx={coursesStyles.screenLayout}>
          {!this.state.isLoading &&
            this.state.courseDetail &&
            this.renderScreen()}
          {this.state.isLoading && this.renderScreenLoading()}
          <div
          style={{
            maxWidth: '1440px',
            width: "100%",
            margin: '0px auto 35px auto'
          }}
        >
          <div
            style={{
              ...webStyle.launcedCourseHead,
              marginBottom: "33px",
              marginTop: 0
            }}
          >
            <Typography variant="h5" style={webStyle.courseTitle}>
              Trending Courses
            </Typography>
            <Button
              endIcon={<ArrowForwardIcon htmlColor="#1721CD" />}
              variant="outlined"
              data-test-id="goTrendingCourseList"
              style={webStyle.courseViewAll}
              onClick={() => this.handleViewCourseList()}
            >
              <Typography variant="subtitle1" style={webStyle.viewAllText}>
                VIEW ALL
              </Typography>
            </Button>
          </div>
          <div style={webStyle.launchedCoursesMain}>
            <IconButton
              data-test-id="backTrendingCourse"
              onClick={() => this.handleTrendingCoursePage("prev")}
              style={{
                display: this.state.pageNo === 1 ? "none" : ""
              }}
              className="change-page-button"
              disabled={this.disabledBackButtons()}
            >
              <img src={backArrowPage} width={48.56} height={48.56} />
            </IconButton>
            <div className="launchTrendCardMain">
              {trendingCoursesSort && trendingCoursesSort.length > 0
                ? trendingCoursesSort.map(
                    (item: LaunchedCourses, index: number) => (
                      <React.Fragment key={item.id}>
                        {getNewLaunchCoursesWeb(
                          ({
                            ...item,
                            isAdd: true
                          } as unknown) as LaunchedCourses,
                          index,
                          CardType.trendingCourses,
                          this.stringToBoolean,
                          this.showCoursePrice,
                          this.handleWishlistStatus,
                          this.handleOpenCourseValidityPopup
                        )}
                      </React.Fragment>
                    )
                  )
                : this.showSkeleton()}
            </div>
            <IconButton
              data-test-id="nextTrendingCourse"
              onClick={() => this.handleTrendingCoursePage("next")}
              className="change-page-button"
              style={{
                ...webStyle.forwardIcon,
                display:
                  this.state.pageNo + 1 === this.state.lastPage ? "none" : ""
              }}
              disabled={this.disabledNextButtons()}
            >
              <img src={forwardArrowPage} width={48.56} height={48.56} />
            </IconButton>
          </div>
        </div>
        </Box>
      </div>
    );
  };

  renderItemValidityDialog = () => {
    const {
      courseValidityDetails,
      selectedValidity,
      isCourseValidityOpen
    } = this.state;
    const courseDescription =
      courseValidityDetails.attributes.short_description;
    const course_name = courseValidityDetails.attributes.course_name;
    return (
      <CustomDialog
        className={"dialogContainer"}
        open={isCourseValidityOpen}
        onClose={this.onClose}
        style={{ padding: 24 }}
      >
        <DialogTitle disableTypography className={"dialogTitleRoot"}>
          <Typography
            data-test-id="courseValidityTitle"
            className={"dialogTitle"}
          >
            {configJSON.courseValidityTitle}
          </Typography>
          <IconButton className={"closeButton"} onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={"dialogContent"}>
          <Typography variant="subtitle1" className={"subTitleCourseValidity"}>
            {course_name}: {courseDescription}
          </Typography>
          <div className={"buttonGroup"}>
            <Button
              data-test-id="1_year"
              variant="outlined"
              color="primary"
              className={`${"button"} ${
                selectedValidity === 1 ? "selected" : ""
              }`}
              onClick={() => this.handleSelectValidity(1)}
            >
              <span className={"buttonLabel"}>
                {selectedValidity === 1 && (
                  <CheckIcon className={"checkIcon"} />
                )}
                {configJSON.validity1Year}
                <div style={{
                  position: 'absolute' as const, 
                  right: 0 , 
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 600,
                   fontFamily: 'Inter',
                   fontSize: 24
                }}><LiaRupeeSignSolid className="rupeeIcon" /> {courseValidityDetails?.attributes?.first_year_price}</div>
              </span>
            </Button>
            {courseValidityDetails.attributes.second_year_price ? (
              <Button
                data-test-id="2_year"
                variant="outlined"
                color="primary"
                className={`${"button"} ${
                  selectedValidity === 2 ? "selected" : ""
                }`}
                onClick={() => this.handleSelectValidity(2)}
              >
                <span className={"buttonLabel"}>
                  {configJSON.validity2year}
                  {selectedValidity === 2 && (
                    <CheckIcon className={"checkIcon"} />
                  )}
                   <div style={{
                  position: 'absolute' as const, 
                  right: 0 , 
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 600,
                   fontFamily: 'Inter',
                   fontSize: 24
                }}><LiaRupeeSignSolid className="rupeeIcon" /> {courseValidityDetails.attributes.second_year_price}</div>
                </span>
              </Button>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", padding: "16px 24px" }}
        >
          <Button className={"cancelButton"} onClick={this.onClose}>
            {configJSON.cancelText}
          </Button>
          <Button
            data-test-id="applyButton"
            variant="contained"
            className={"applyButton"}
            onClick={() =>
              this.handleChartStatus(
                courseValidityDetails.hasOwnProperty("isAdd"),
                courseValidityDetails.id,
                courseValidityDetails.type,
                selectedValidity
              )
            }
          >
            {configJSON.applyText}
          </Button>
        </DialogActions>
      </CustomDialog>
    );
  };

  courseDetailList(value: CourseDetailsAttributes) {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {obj.map(item => (
            <div
              style={{
                display: "inline-flex",
                gap: 10,
                width: "100%",
                alignItems: "center",
                borderBottom: "1px solid #BEBEBE",
                padding: "5px 0"
              }}
            >
              <Typography
                variant="h6"
                style={{
                  display: "inline-flex",
                  width: "50%",
                  gap: "6px",
                  alignItems: "center",
                  fontWeight: "bold"
                }}
              >
                {item.icon}
                <strong>{item.label}</strong>
              </Typography>
              <StyledTypography variant="h6">
                {item.valueName === "all_course_class_duration"
                  ? this.convertToHoursAndMinutes(value[
                      (item.valueName as unknown) as keyof CourseDetailsAttributes
                    ] as number, true)
                  : value[
                      (item.valueName as unknown) as keyof CourseDetailsAttributes
                    ]}
              </StyledTypography>
            </div>
          ))}
          <div
            style={{
              display: "inline-flex",
              gap: 10,
              width: "100%",
              alignItems: "center",
              borderBottom: "1px solid #BEBEBE",
              padding: "5px 0"
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                display: "inline-flex",
                width: "50%",
                gap: "6px"
              }}
            >
              <FiShare2 fontSize={"inherit"} />
              <strong>{configJSON.shareOnText}</strong>
            </Typography>
            <div style={{ width: "50%", display: "inline-flex", gap: 5 }}>
              <img
                src={facebookIcon}
                width={20}
                height={20}
                alt="facebook logo"
                data-test-id="facebookNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://www.facebook.com/")
                }
              />
              <img
                src={instagramIcon}
                width={20}
                height={20}
                alt="instagram logo"
                data-test-id="instagramNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://www.instagram.com/")
                }
              />
              <img
                src={whatsappIcon}
                width={20}
                height={20}
                alt="whatsapp logo"
                data-test-id="whatsappNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://web.whatsapp.com/")
                }
              />
              <img
                src={telegramICon}
                width={20}
                height={20}
                alt="telegram logo"
                data-test-id="telegramNavigate"
                onClick={() =>
                  this.copyAndNavigate("https://web.telegram.org/")
                }
              />
            </div>
          </div>
        </div>
        <Paper
          style={{
            marginTop: "10px",
            padding: "4px 5px",
            display: "flex",
            alignItems: "center",
            position: "relative",
            boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.14)",
            borderRadius: 10,
            background: "#eee"
          }}
        >
          <InputBase
            style={{
              flex: 1,
              marginLeft: "10px",
              marginRight: "90px"
            }}
            readOnly
            value={window.location.href}
          />
          <Button
            onClick={() => this.copyTextToClipboard(window.location.href)}
            data-test-id="copyToClipboardTestId"
            style={{
              borderRadius: 7,
              background: "linear-gradient(133.48deg, #3cc9e1, #03859b)",
              height: 32,
              position: "absolute",
              right: 5,
              fontSize: 10,
              color: "white",
              display: "inline-flex"
            }}
            startIcon={<PiClipboardTextLight fontSize="inherit" />}
            variant="contained"
          >
            {configJSON.copyText}
          </Button>
        </Paper>
      </>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NotificationAlertMessage
          dataTestId="alertTestId"
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
       <Dialog open={this.state.isCourseVideoPlay} onClose={this.handleCourseVideoPlay} maxWidth="md" fullWidth>
          <Box position={'absolute'} right={0} top={0}>
            <IconButton onClick={this.handleCourseVideoPlay}>
              <CloseIcon style={{ color: "#000" }}/>
            </IconButton>
          </Box>
          <Box padding={"16px"} style={{ background: "#fff" ,paddingTop: 48 }}>
            <iframe
             width="100%" 
             height="450px"
              src={`${this.state.courseDetail?.preview_video_url}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              style={{ display: "block" }}
            >
            </iframe>
          </Box>
        </Dialog>
        <HeaderWeb id={""} navigation={this.props.navigation} />
        {this.state.isCourseValidityOpen && this.renderItemValidityDialog()}
        <main
          style={{
            overflowX: "scroll",
            maxWidth: "1920px",
            width: "100%"
          }}
        >
          <div
            style={{
              padding: "25px 12px",
              paddingTop: 0
            }}
          >
            {this.renderView()}
            <Footer id="" navigation={this.props.navigation} />
          </div>
        </main>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "20px !important",
    boxShadow: "none",
    margin: "16px 0"
  }
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    flexDirection: "row-reverse",
    color: "white",
    background: "linear-gradient(310.05deg, #439EAD 7.85%, #206575 99.2%)",
    gap: 20,
    borderRadius: 20,
    transition: "0.3s ease",
    "&:hover": {
      filter: "brightness(1.2)"
    },
    "&$expanded": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  expanded: {}
})(MuiAccordionSummary);

enum CourseStylesProperties {
  smallDescription = "smallDescription",
  screenLayout = "screenLayout",
  mainLayout = "mainLayout",
  leftColumn = "leftColumn",
  rightColumn = "rightColumn",
  rightColumnCard = "rightColumnCard",
  column = "column",
  header = "header",
  outlinedButton = "outlinedButton",
  containedButton = "containedButton",
  aboutCourse = "aboutCourse",
  courseViewAll = "courseViewAll",
  viewAllText = "viewAllText",
  titleText = "titleText"
}
const coursesStyles: Record<CourseStylesProperties, React.CSSProperties> = {
  titleText: {
    color: "#858585",
    fontWeight: 700,
    marginTop: 15,
    marginBottom: 15
  },
  viewAllText: {
    color: "#1220C1",
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.3px"
  },
  courseViewAll: {
    display: "flex",
    columnGap: "10px",
    borderRadius: "31.826px",
    border: "1.8px solid #1721CD",
    background: "#FFF"
  },
  aboutCourse: {
    display: "flex",
    columnGap: "11px",
    width: "100%"
  },
  header: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    height: "100%",
    justifyContent: "space-between",
    padding: "24px 0px"
  },
  screenLayout: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  mainLayout: {
    width: "100%",
    boxSizing: "border-box"
  },
  column: { display: "flex", flexDirection: "column" },
  leftColumn: {
    width: "70%",
    gap: "19px"
  },
  rightColumn: {
    width: "30%",
    gap: "30px"
  },
  rightColumnCard: {
    boxSizing: "border-box",
    padding: 15,
    borderRadius: 12,
    background: "white",
    boxShadow: "0px 1px 8px 0px #00000025",
    gap: "10px",
    display: "flex",
    flexDirection: "column"
  },
  smallDescription: {
    display: "inline-flex",
    gap: 30,
    alignItems: "center",
    margin: "10px 0"
  },
  outlinedButton: {
    background: "white",
    color: "#FD7101",
    borderRadius: "32px",
    border: "2px solid #FD7101",
    boxShadow: "0"
  },
  containedButton: {
    background: "linear-gradient(133.48deg, #FF5C01 -26.6%, #FFA302 118.85%)",
    color: "white",
    borderRadius: "32px",
    boxShadow: "0"
  }
};

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  fixed: {
    height: "58px"
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      borderRadius: "12px",
      width: "100%",
      backgroundColor: "#ffd735"
    }
  }
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '24%',
      textTransform: "none",
      background: "#e3e3e3",
      color: "#212121",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(23),
      marginRight: theme.spacing(1),
      padding: "6px 40px",
      borderRadius: "0px 0px 12px 12px",
      "@media (min-width: 900px) and (max-width: 1280px)": {
        padding: "6px 16px",
        fontSize: 16
      },
      "@media (min-width: 1280px) and (max-width: 1419px)": {
        padding: "6px 24px",
      },
      "&.Mui-selected": {
        background: "linear-gradient(180deg, #ffed65, #fcb910)",
        fontWeight: 600
      },
      "&:focus": {
        opacity: 1
      }
    }
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

// Customizable Area End
