import React, { Component } from 'react';
import { Box, Button, Dialog, styled, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import StarsOutlinedIcon from '@material-ui/icons/StarsOutlined';
import { removeStorageData ,getStorageData} from 'framework/src/Utilities';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import { FiDownload, FiPhone, FiLogOut } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {  Close, DeleteOutline } from '@material-ui/icons';
const config = require("framework/src/config.js")
interface CustomDrawerProps {
  userPic: string;
  fullName: string;
  pathname: string;
  phoneNumber: string;
}

interface CustomDrawerState {
  isDelete:boolean;
}

const CustomListItemIcon = styled(ListItemIcon)({
  minWidth: '30px',
  color: 'inherit',
  '& .MuiSvgIcon-root': {
    width: '24px !important',
    height: '24px !important'
  },
});
const itemsList = [
  {
    label: 'My Profile',
    icon: <PersonOutlinedIcon />,
    href: '/UserProfile'
  },
  {
    label: 'Notification',
    icon: <NotificationsNoneOutlinedIcon />,
    href: '/Notifications'
  },
  {
    label: 'My Purchases',
    icon: <ShoppingCartOutlinedIcon />,
    href: '/Purchases'
  },
  {
    label: 'Downloads',
    icon: <FiDownload fontSize={24} />,
    href: '/Downloads'
  },
  {
    label: 'My Wishlist',
    icon: <FavoriteBorderIcon />,
    href: '/Wishlist'
  },
  {
    label: 'My Cash',
    icon: <StarsOutlinedIcon />,
    href: '/MyCash'
  },
  {
    label: 'Contact us',
    icon: <FiPhone fontSize={24} />,
    href: '/ContactusWeb'
  },
  {
    label: 'Logout',
    icon: <FiLogOut fontSize={24} />,
    href: '/?logout_user=true',
    callback: () => {
      removeStorageData('emailOTPToken');
      removeStorageData('loginEmail');
      removeStorageData('profilePic');
      removeStorageData('profileUsername');
      removeStorageData('userRole');
      removeStorageData('cartCount');
      removeStorageData('wishlistCount');
      removeStorageData('coupon_code');
      removeStorageData('discount_type');
    },
  },
  {
    label: 'Delete Account',
    icon: <DeleteOutline />,
    href: ''
  },
];

class UserSidebarMenu extends Component<CustomDrawerProps, CustomDrawerState> {
  constructor(props: CustomDrawerProps) {
    super(props);

    this.state = {
      isDelete:false
    };
  }

  cancelBtn=()=>{
    this.setState({isDelete:false})
  }
  deleteAcc=async ()=>{
    const token = await getStorageData('loginToken')
    try {
      const response = await fetch(`${config.baseURL}/bx_block_login/login/delete_account`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'token': token, 
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete account. Please try again.');
      }

    } finally {
      removeStorageData('emailOTPToken');
      removeStorageData('loginEmail');
      removeStorageData('profilePic');
      removeStorageData('profileUsername');
      removeStorageData('userRole');
      removeStorageData('cartCount');
      removeStorageData('wishlistCount');
      removeStorageData('coupon_code');
      removeStorageData('discount_type');
      this.setState({ isDelete: false });
      window.location.href="/"
    }
}


  render() {
    return (
      <>
      <div
        style={{ width: '100%', background: '#FFEFE7', borderRadius: '8px' }}
      >
        <div
          style={{
            borderBottom: '2px solid #FF8256',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 130,
            background: '#FFDBC9',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            fontSize: 16,
            gap: 3,
          }}
        >
          <Avatar
            style={{ width: 64, height: 64 }}
            src={this.props.userPic}
            alt=""
          />
          {this.props.fullName ? (
            <strong style={{ margin: 2 }}>{this.props.fullName}</strong>
          ) : (
            <Skeleton variant="text" style={{ width: '60%' }} />
          )}
          {this.props.phoneNumber !== '+91 ' ? (
            <span style={{ fontSize: 14 }}>{this.props.phoneNumber}</span>
          ) : (
            <Skeleton variant="text" style={{ width: '70%' }} />
          )}
        </div>
        <List>
          {itemsList.map(item => {
            const isSelected = item.href === this.props.pathname;
            return (
              <ListItem
                button
                component={item.href ? Link : 'div'}
                onClick={() => {
                  if (item.label === "Delete Account") {
                      this.setState({ isDelete: true });
                  }
                  if (item.callback) {
                      item.callback();
                  }
              }}
                to={item.href || undefined}
                key={item.label}
                data-test-id={item.label}
                style={{
                  paddingLeft: 25,
                  color: isSelected ? '#206575' : 'black',
                  ...(isSelected
                    ? {
                        fontWeight: 'bold',
                        background: 'transparent'
                      }
                    : {}),
                }}
              >
                <CustomListItemIcon>{item.icon}</CustomListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            );
          })}
        </List>
      </div>
      <CustomDialog open={this.state.isDelete} >
        <Box style={{display:'flex',justifyContent:'end'}}>
          <Close style={{width:'18px',height:'18px',cursor:'pointer',position:'absolute',top:12,right:20}} onClick={this.cancelBtn}/>
        </Box>
        <Box style={{display:'flex',justifyContent:'center'}}>
          <Box style={webStyle.deletebox}>
            <DeleteOutline style={{color:'#DC2626'}}/>
          </Box>
          </Box>
          <Typography style={webStyle.text}>Are you sure you want to delete your account?</Typography>
          <Button onClick={this.deleteAcc} style={webStyle.deleteButton}>Delete account</Button>
          <Button onClick={this.cancelBtn} style={webStyle.cancelBtn}>Cancel</Button>
      </CustomDialog>
      </>
    );
  }
}

export default UserSidebarMenu;

const CustomDialog = styled(Dialog)({
  borderRadius: '16px',
  padding: '40px 38px',
  position:'relative',
  '& .MuiDialog-paperWidthSm': {
    minWidth: '363px',
    borderRadius: '16px',
    padding: '40px 38px',
    maxWidth: '363px',
    position: 'relative',
    paddingBottom: '27px',
    "@media only screen and (max-width: 816px)": {
      minWidth: '200px'
    },
    "@media only screen and (max-width: 553px)": {
      minWidth: '200px'
    }
  }
})
const webStyle={
  text:{
    color:'#000000',
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: 400,
    lineHeight: '40px',
    textAlign: 'center' as 'center',
    marginTop:'35px'
  },
  deleteButton:{
    color:'#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '22.29px',
    fontWeight: 600,
    lineHeight: '28.19px',
    background:'#FF8256',
    borderRadius:'20px',
    marginTop:'54px',
    marginBottom:'25px',
    cursor:'pointer'
  },
  cancelBtn:{
    color:'#FF8256',
    fontFamily: 'Inter',
    fontSize: '23.29px',
    fontWeight: 600,
    lineHeight: '28.19px',
    cursor:'pointer'
  },
  center:{display:'flex',justifyContent:'end'},
  deletebox:{padding:'5px 10px 11px 8px',background:'pink',
    borderRadius:'50%',width:'30px',height:'30px',display:'flex',justifyContent:'center'}
}