import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    withStyles,
    Theme,
    IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ContactusController, { configJSON, Props } from "./ContactusController";
import HelpCenterHeaderWeb from "../../../components/src/HelpCenterHeader.web";
import HeaderWeb from "../../../components/src/Header.web";
import { blueCircle } from "../../TermsAndConditions/src/assets";
import { facebookIcon, instagramIcon, telegramIcon, twitterIcon, customerService, youtubeIcon } from "./assets";
import FooterWeb from "../../../components/src/Footer.web";
import { FaWhatsapp } from "react-icons/fa";

const styles = (theme: Theme) => ({
    root: {
        backgroundColor: '#fff',
        padding: theme.spacing(4, 0),
        textAlign: 'center' as const,
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(3, 0),
        color: '#fff',
        marginBottom: theme.spacing(4),
    },
    icon: {
        margin: theme.spacing(1),
    },
    contactDetails: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    socialMedia: {
        margin: theme.spacing(2, 0),
        display: 'flex',
        flexDirection: "column" as const
    },
    footer: {
        backgroundColor: '#f5f5f5',
        padding: theme.spacing(4),
        marginTop: theme.spacing(6),
    },
    footerText: {
        color: '#666',
    },
    iconButton: {
        color: '#000 !important',
    },
    contact_address: {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 500,
        color: '#000000'
    },
    followus_option: {
        fontFamily: 'Inter',
        fontSize: '25.57px',
        fontWeight: 600,
        color: '#323232'
    },
    contanctus_heading: {
        fontWeight: 600,
        fontSize: '28px',
        fontFamily: 'Inter',
        lineHeight: '44px',
        color: '#212121'
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

class ContactusWeb extends ContactusController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
    // Customizable Area Start
        const { classes } = this.props;
        const { contactUsWeb } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <HeaderWeb navigation={this.props.navigation} id={""} />
                <HelpCenterHeaderWeb
                    title={configJSON.headingTitle}
                    subTitle={configJSON.headingSubTitle}
                />
                    <Box style={{ margin: 50, position: 'relative' , marginBottom: 100 }}>
                        <Box width={'446px'} height={"446px"} style={webStyles.blueImage}>
                            <img src={blueCircle} />
                        </Box>
                        <Box width={'446px'} height={"446px"}
                            style={{
                                position: "absolute",
                                bottom: -60,
                                right: 35,
                                zIndex: -2
                            }}
                        >
                            <img src={blueCircle} />
                        </Box>
                        <Box style={webStyles.contentContainer}>
                            <Grid container spacing={4} justify="center">
                                <Grid item xs={12} sm={5} style={{ display: 'flex', alignItems: "center" }}>
                                    <Box display={'flex'} flexDirection={'column'} style={{ gap: 24 }}>
                                        <Typography data-test-id="chseExpress_title" variant="h6" className={classes.contanctus_heading}>{configJSON.chseExpressText}</Typography>
                                        <Typography variant="body1" className={classes.contact_address}>
                                            <strong> {configJSON.addressText}:</strong> {contactUsWeb.address}
                                        </Typography>
                                        <Typography variant="body1" className={classes.contact_address}><strong>{configJSON.mobileText}:</strong> {contactUsWeb.full_phone_number}</Typography>
                                        <Typography variant="body1" className={classes.contact_address}><strong>{configJSON.emailText}:</strong> {contactUsWeb.email}</Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={7} className={classes.socialMedia}>
                                    <Box display={'flex'} flexDirection={'column'} style={{ gap: 21 }}>
                                        <Box display={'flex'} alignItems={'center'} style={{ gap: 26 }}>
                                            <IconButton className={classes.iconButton} aria-label={configJSON.customerService} disabled>
                                                <img src={customerService} />
                                            </IconButton>
                                            <Typography className={classes.followus_option}>{configJSON.customerService}</Typography>
                                        </Box>
                                        <Box display={'flex'} alignItems={'center'} style={{ gap: 26 }}>
                                            <IconButton className={classes.iconButton} aria-label="Facebook" disabled>
                                                <img src={facebookIcon} />
                                            </IconButton>
                                            <Typography className={classes.followus_option}>{configJSON.facebook}</Typography>
                                        </Box>
                                        <Box display={'flex'} alignItems={'center'} style={{ gap: 26 }}>
                                            <IconButton className={classes.iconButton} aria-label="WhatsApp" disabled>
                                                <FaWhatsapp style={{ width: 41, height: 41 }} />
                                            </IconButton>
                                            <Typography className={classes.followus_option}>{configJSON.whatsapp}</Typography>
                                        </Box>
                                        <Box display={'flex'} alignItems={'center'} style={{ gap: 26 }}>
                                            <IconButton className={classes.iconButton} aria-label="Telegram" disabled>
                                                <img src={telegramIcon} />
                                            </IconButton>
                                            <Typography className={classes.followus_option}>{configJSON.telegram}</Typography>
                                        </Box>
                                        <Box display={'flex'} alignItems={'center'} style={{ gap: 26 }}>
                                            <IconButton className={classes.iconButton} aria-label="Twitter" disabled>
                                                <img src={twitterIcon} />
                                            </IconButton>
                                            <Typography className={classes.followus_option}>{configJSON.twitter}</Typography>
                                        </Box>
                                        <Box display={'flex'} alignItems={'center'} style={{ gap: 26 }}>
                                            <IconButton className={classes.iconButton} aria-label="Instagram" disabled>
                                                <img src={instagramIcon} />
                                            </IconButton>
                                            <Typography className={classes.followus_option}>{configJSON.instagram}</Typography>
                                        </Box>
                                        <Box display={'flex'} alignItems={'center'} style={{ gap: 26 }}>
                                            <IconButton className={classes.iconButton} aria-label="YouTube" disabled>
                                                <img src={youtubeIcon} />
                                            </IconButton>
                                            <Typography className={classes.followus_option}>{configJSON.youtube}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                <FooterWeb navigation={this.props.navigation} id={""} />
            </ThemeProvider>
        );
    // Customizable Area End

    }
}

// Customizable Area Start
const webStyles = {
    contentContainer: {
        borderRadius: '23px',
        padding: '40px 80px',
        boxShadow: '0px 1px 8px 0px #00000025',
        background: "#FFFFFF",
        minHeight: 450
    },
    blueImage: {
        position: 'absolute' as const,
        left: -25,
        top: 10,
        opacity: 0.8,
        zIndex: -2
    }
};
export default withStyles(styles)(ContactusWeb);
export {ContactusWeb}
// Customizable Area End
